<template>
  <div id="navbar">
    <v-card color="grey lighten-4" flat tile>
      <v-toolbar class="toolBar" dense height="65px">
        <v-app-bar-nav-icon @click="showSideBar"></v-app-bar-nav-icon>

        <v-toolbar-title :class="{ hidden: !getSideBarMin }">
          {{ school_name ? school_name : "SMS" }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="nav-icon" id="notification_icon">
              notifications
            </v-icon>
          </template>
          <v-list id="notification">
            <v-list-item v-for="(item, index) in notifications" :key="index">
              <v-list-item-title class="notification-item" :class="item.class">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
        <!-- notification -->
        <v-btn icon to="/checkout" v-if="canCheckout">
          <v-icon color="#7296fb" :title="$t('Student Checkout')" large
            >mdi-account-voice</v-icon
          >
        </v-btn>
        <v-menu
          offset-y
          style="z-index: 1111 !important"
          id="notification-menu"
          max-width="400px"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-badge
                color="#7297ff"
                :content="
                  userNotifications.length > 0 ? userNotifications.length : '0'
                "
              >
                <v-icon
                  class="main-color notification-icon"
                  large
                  :title="$t('Notifications')"
                  >notifications</v-icon
                >
              </v-badge>
            </v-btn>
          </template>
          <v-list id="notificationMenu" style="max-height: 400px">
            <v-list-item
              v-for="(notification, index) in userNotifications"
              :key="index"
            >
              <v-list-item-avatar>
                <img :src="notifImage" alt="notification image" />
              </v-list-item-avatar>

              <v-list-item-content class="pt-0">
                <a
                  v-if="notification.link"
                  :href="notification.link"
                  target="_blank"
                >
                  {{ notification.body }}
                </a>
                <p v-else class="mb-0 mt-3 notif-content">
                  {{ notification.body }}
                </p>
                <v-list-item-subtitle class="main-color">{{
                  notification.time
                }}</v-list-item-subtitle>

                <hr class="mb-0 mt-2" />
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="userNotifications.length == 0">
              <v-list-item-title class="item.class">
                No Notifications
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="readNotification"
              v-if="userNotifications.length > 0"
              style="min-height: 32px !important; margin-bottom: 5px"
            >
              <v-list-item-title class="text-center showAll blue--text">
                {{ $t("Mark all as read") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <router-link
          :to="'/inbox'"
          style="position: relative; margin-right: 10px; margin-left: 10px"
        >
          <v-badge
            avatar
            bordered
            overlap
            :content="messageCount"
            v-if="messageCount > 0"
            color="#7297ff"
          >
            <v-icon class="main-color email-icon" large :title="$t('Inbox')"
              >email</v-icon
            >
          </v-badge>
          <v-icon
            v-else
            class="main-color email-icon"
            large
            :title="$t('Inbox')"
            >email</v-icon
          >
        </router-link>

        <label style="margin: 10px; color: #7296fb"
          ><a @click="ChangeLanguage">{{
            currentAppLocale == "en" || currentAppLocale == undefined
              ? $t("AR")
              : $t("EN")
          }}</a></label
        >
        <span class="ml-2" v-if="loginAs != true && loginAs != 'true'">
          |
        </span>
        <p
          style="margin-left: 10px; margin-top: 30px"
          v-if="loginAs != true && loginAs != 'true'"
          class="username"
        >
          <span>
            {{ username }}
          </span>

          <span style="display: block; text-align: left">
            {{ $t(role) }}
          </span>
        </p>

        <v-menu
          offset-y
          style="z-index: 1111 !important; width: 200px"
          v-if="loginAs == false || loginAs == 'false'"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="nav-icon"
              id="account_circle_icon"
              style="color: #444"
              >arrow_drop_down</v-icon
            >
          </template>

          <v-list id="accountMenu">
            <div v-for="(item, index) in accountitems" :key="index">
              <v-list-item link :to="item.path" v-if="item.enabled">
                <v-list-item-title
                  class="notification-item"
                  :class="item.class"
                  @click="
                    if (item.method == 'logout') logout();
                    else if (item.method == 'switchRole') switchRole();
                    else hide();
                  "
                >
                  <v-icon>{{ item.icon }}</v-icon>
                  <label
                    v-if="
                      item.title != 'last Login :' &&
                      item.title != 'اخر تسجيل للدخول :'
                    "
                    >{{ item.title }}</label
                  >
                  <label
                    v-if="
                      (item.title == 'last Login :' ||
                        item.title == 'اخر تسجيل للدخول :') &&
                      lastLogin != 'null'
                    "
                    >{{ $t("Last Login") }} : {{ lastLogin }}</label
                  >
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <span v-if="loginAs != true && loginAs != 'true'">
          <img
            v-if="user_logo == null || user_logo == 'null'"
            id="logo"
            src="../assets/avatar.png"
            style="width: 40px; height: 40px; border-radius: 50%"
          />
          <img
            v-else
            :src="user_logo"
            :alt="username"
            style="width: 40px; height: 40px; border-radius: 50%"
          />
        </span>
        <div v-if="loginAs == true || loginAs == 'true'" class="loginAs">
          <v-row class="loginAs-row">
            <!-- <v-col cols="1"></v-col> -->
            <v-col cols="2" md="1">
              <v-menu offset-y style="z-index: 1111 !important">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="nav-icon"
                    id="account_circle_icon"
                    large
                    color="white"
                    >account_circle</v-icon
                  >
                  <!-- <v-icon
                    v-on="on"
                    class="nav-icon"
                    id="account_circle_icon"
                    large
                    color="white"
                  >
                    arrow_drop_down
                  </v-icon> -->
                </template>
                <v-list id="accountMenu">
                  <div v-for="(item, index) in accountitems" :key="index">
                    <v-list-item link :to="item.path" v-if="item.enabled">
                      <v-list-item-title
                        class="notification-item"
                        :class="item.class"
                        @click="
                          if (item.method == 'logout') logout();
                          else if (item.method == 'switchRole') switchRole();
                          else hide();
                        "
                      >
                        <v-icon>{{ item.icon }}</v-icon>
                        <label v-if="item.title != 'last Login :'">{{
                          item.title
                        }}</label>
                        <label
                          v-if="
                            item.title == 'last Login :' && lastLogin != 'null'
                          "
                          >Last Login : {{ lastLogin }}</label
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="8" md="5">
              <div style="font-weight: bold">
                <p style="color: white" class="loginAs-title">
                  <span> logged in as : </span>
                  {{ user_name }}
                </p>
              </div>
            </v-col>
            <v-col cols="2" md="6" class="d-none d-md-flex px-0">
              <v-btn
                style="color: white; font-size: 14px; background-color: #7297ff"
                @click="deImpersonate"
                >{{ $t("Switch Back To") }} {{ old_user_name }}</v-btn
              >
            </v-col>
            <v-col cols="2" class="d-flex d-md-none px-0">
              <v-btn
                style="color: white; background-color: #7297ff"
                small
                @click="deImpersonate"
                ><v-icon>arrow_back</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-toolbar>
    </v-card>

    <div id="mask" v-if="shouldChooseRole">
      <v-dialog
        v-model="shouldChooseRole"
        class="role-dialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-radio-group v-model="selectedRole" row class="ChangeRole">
                <div class="radioGroupContainer">
                  <v-radio
                    v-for="(role, index) in roles"
                    :key="index"
                    :label="$t(role)"
                    :value="role"
                  >
                    <template slot="label">
                      <div>
                        <img
                          :class="role == selectedRole ? 'active' : ''"
                          :src="getImageUrl(role)"
                          alt
                        />
                      </div>
                      <label class="roleLabel" for>{{ $t(role) }}</label>
                    </template>
                  </v-radio>
                </div>
              </v-radio-group>
            </v-container>
          </v-card-text>

          <v-card-actions class="space">
            <v-spacer></v-spacer>

            <v-btn
              class="modal-btn-save saveBtn"
              @click="selectRole"
              :loading="loading"
              :disabled="loading"
              >{{ $t("Continue") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- user update data modal -->
    <div id="updat-data">
      <v-dialog v-model="updateDataDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ $t("Welcome to") }}
              {{ school_name ? school_name : "SMS" }}</span
            >
            <p>
              {{
                $t(
                  "Please enter your email and mobile number that will be used to login to the system."
                )
              }}
            </p>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <br />
                  <v-row>
                    <v-col sm="12" md="12" xs="12" cols="12">
                      <v-row>
                        <v-col sm="4" md="4" xs="4" cols="4">
                          <div id="country-code" style="margin-top: 10px">
                            <label>{{ $t("Code") }}</label>
                            <v-autocomplete
                              :items="Codes"
                              required
                              @change="hideLabel = true"
                              item-text="title"
                              item-value="value"
                              v-model="country_code_id"
                              solo
                              :rules="[validationRules.required]"
                            >
                              <template slot="selection" slot-scope="data">
                                <!-- <img src="../../assets/flags/AF.png" />
                                {{ data.title }}-->

                                <v-flex xs style="margin-left: 10px">
                                  <v-avatar size="25">
                                    <img :src="data.item.icon" />
                                  </v-avatar>
                                </v-flex>
                                <v-flex class="ml-3">{{
                                  data.item.title
                                }}</v-flex>
                              </template>
                              <template slot="item" slot-scope="data">
                                <v-list-item-avatar
                                  style="
                                    width: 20px;
                                    min-width: 20px;
                                    height: 20px;
                                  "
                                >
                                  <img :src="data.item.icon" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="data.item.title"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </div>

                          <div v-if="validation_errors.country_code_id">
                            <p
                              class="red--text"
                              v-for="(
                                error, index
                              ) in validation_errors.country_code_id"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                        <!-- country_code_id -->
                        <v-col sm="8" md="8" xs="8" cols="8">
                          <label>{{ $t("Mobile") }}</label>
                          <v-text-field
                            v-model="mobile"
                            solo
                            @focus="onFocus()"
                            :rules="[
                              validationRules.required,
                              mobileRule(country_code_id, mobile),
                            ]"
                          ></v-text-field>
                          <div v-if="validation_errors.mobile">
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors.mobile"
                              :key="index"
                              @focus="onFocus()"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- email -->
                    <!-- <v-col sm="12" md="12" xs="12" cols="12">
                      <div style="padding: 0 10px">
                        <label>{{ $t("Email") }}</label>
                        <v-text-field
                          v-model="email"
                          @focus="onFocus()"
                          :rules="[
                            validationRules.required,
                            validationRules.email,
                          ]"
                          solo
                        ></v-text-field>
                        <div v-if="validation_errors.email">
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.email"
                            :key="index"
                            @focus="onFocus()"
                          >
                            {{ error }}
                          </p>
                        </div>
                      
                      </div>
                    </v-col> -->
                  </v-row>
                </v-form>
              </v-card-text>
            </v-container>
          </v-card-text>

          <v-card-actions class="space">
            <v-spacer></v-spacer>

            <v-btn class="modal-btn-cancel" @click="caucelUpdat">{{
              $t("cancel")
            }}</v-btn>
            <v-btn class="modal-btn-save saveBtn" @click="saveDate">{{
              $t("Save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <notificationpopup ref="notification"></notificationpopup>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { validationMixin } from "../mixins/validationMixin";
import notificationpopup from "../components/notificationpopup";
import Acl from "../acl";
// import ACL from "../acl";

export default {
  name: "NavBarComponent",
  props: {},
  components: {
    notificationpopup,
  },
  mixins: [validationMixin],
  data() {
    return {
      canCheckout: Acl.checkPermission("checkout"),
      loading: false,
      notifImage: localStorage.logo,
      userNotifications: [],
      old_user_name: localStorage.old_user_name,
      loginAs: localStorage.loginAs,
      user_name: localStorage.user_name,
      updateDataDialog: false,
      lastLogin: localStorage.lastLogin,
      updateData: localStorage.updateData,
      username: localStorage.user_name,
      user_logo: localStorage.user_logo,
      role: localStorage.selectedRole,

      notifications: [
        { title: this.$i18n.t("show"), class: "type1" },
        { title: this.$i18n.t("hide"), class: "type2" },
        { title: this.$i18n.t("Click Me"), class: "type3" },
        { title: this.$i18n.t("Click Me 2"), class: "type1" },
      ],
      messageCount: 0,
      accountitems: [
        {
          title: this.$i18n.t("My Profile"),
          icon: "person",
          method: "",
          class: "",
          path: "/profile",
          enabled: true,
        },
        {
          title: this.$i18n.t("last Login") + " :",
          // icon: "check_circle",
          // method: "logout",
          class: "last login",
          // path: "",
          enabled: true,
        },
        /* {
          title: "Tasks",
          icon: "menu",
          method: "",
          class: "",
          path: "",
          enabled: true
        },*/
        {
          title: this.$i18n.t("Switch Role"),
          icon: "cached",
          method: "switchRole",
          class: "",
          path: "",
          enabled: JSON.parse(localStorage.roles).length > 1,
        },
        {
          title: this.$i18n.t("Logout"),
          icon: "check_circle",
          method: "logout",
          class: "",
          path: "",
          enabled: true,
        },
      ],

      valid: true,
      mobile: "",
      email: "",
      country_code_id: 187,
      validation_errors: {
        mobile: "",
        email: "",
        country_code_id: "",
      },
      Codes: [],
      school_name: "",
      roles: JSON.parse(localStorage.roles),
      shouldChooseRole: false,
      selectedRole: localStorage.selectedRole,
      modalTitle: this.$i18n.t("Switch Account"),
      vacationsDays: [],
      vacationNote: "",
      themeColor: "#07D970",
      fontColor: "#ffffff",
    };
  },
  computed: {
    ...mapGetters(["getApiUrl", "getVacationsDays"]),
  },
  methods: {
    ChangeLanguage() {
      if (this.currentAppLocale == "en" || this.currentAppLocale == undefined) {
        localStorage.locale = "ar";
      } else {
        localStorage.locale = "en";
      }
      location.reload();
    },
    getMessageCount() {
      axios
        .get(this.getApiUrl + "/inbox/getMessageCount", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.messageCount = response.data.data;
        });
    },
    deImpersonate() {
      axios
        .get(this.getApiUrl + "/deImpersonate/" + localStorage.old_user, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            let gradeClass = response.data.data.gradeClass;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.gradeClass = gradeClass;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveDate() {
      if (this.valid == false) {
        this.validate();
      } else {
        console.log("dsfs");
        axios
          .post(
            this.getApiUrl + "/users/updateDataMobile",
            {
              mobile: this.mobile,
              // email: this.email,
              country_code_id: this.country_code_id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.updateDataDialog = false;
            }
          });
      }
    },
    caucelUpdat() {
      this.updateDataDialog = false;
      this.validation_errors = {
        email: "",

        mobile: "",

        country_code_id: "",
      };
      localStorage.updateData = true;
    },
    onFocus() {
      this.validation_errors = {
        email: "",
        mobile: "",
      };
    },
    logout() {
      // logout in server
      axios.get(this.getApiUrl + "/logout", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
      let currentAppLocale =
        this.currentAppLocale !== undefined &&
        this.currentAppLocale !== "undefined"
          ? this.currentAppLocale
          : "en";
      localStorage.clear();
      this.currentAppLocale = currentAppLocale;
      // this.$router.push("/login");
      window.location = "/login";
    },
    show() {
      // console.log("show");
    },
    hide() {
      // console.log("hide");
    },
    selectRole() {
      if (this.selectedRole) {
        // console.log(this.selectedRole);
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/selectRole",
            {
              role: this.selectedRole,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            localStorage.permissionsObject = null;
            // console.log(response.data.data.permissions);
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            // console.log(localStorage.permissionsObject);
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.selectedSomeRole = true;
            localStorage.selectedRole = this.selectedRole;
            this.shouldChooseRole = false;

            if (
              this.$router.currentRoute.query.redirectTo &&
              this.$router.currentRoute.query.redirectTo != undefined
            ) {
              window.location =
                "/" + this.$router.currentRoute.query.redirectTo;
            } else {
              window.location = Acl.findHisDashboard();
            }
          });
      }
    },
    switchRole() {
      localStorage.selectedSomeRole = false;
      this.modalTitle = this.$i18n.t("Switch Account");
      this.shouldChooseRole = true;
    },
    getImageUrl(role) {
      return require("../assets/images/" + role + ".png");
    },
    showSideBar() {
      this.$store.commit("setSidebarDisplay");
    },

    arrowNavigation() {
      let _this = this;
      let i = 0;
      $(document).ready(function () {
        $(document).keydown(function (e) {
          // left click
          if (e.keyCode == 37) {
            if (_this.shouldChooseRole == true) {
              if (i >= 0) {
                i = i > 1 ? i - 1 : i;
                console.log(i);
                _this.selectedRole = _this.roles[i - 1];
                $(".saveBtn").focus();
                setTimeout(() => {
                  $(".saveBtn").focus();
                }, 100);
              }
            }
          }
          // right click
          if (e.keyCode == 39) {
            if (_this.shouldChooseRole == true) {
              if (i < _this.roles.length) {
                i++;
                console.log(i);
                _this.selectedRole = _this.roles[i - 1];

                $(".saveBtn").focus();
                setTimeout(() => {
                  $(".saveBtn").focus();
                }, 100);
              }
            }
          }
        });
      });
    },
    getVacationDate() {
      let today = new Date();
      let date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

      axios
        .post(
          this.getApiUrl + "/school/checkVacationDate",
          {
            date: date,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.vacationsDays = response.data.data;
          }
        });
    },
    getNotification() {
      // this.userNotifications = [];
      axios
        .get(this.getApiUrl + "/user/notifications", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.userNotifications = response.data.data;
          }
        });
    },
    readNotification() {
      axios
        .get(this.getApiUrl + "/user/notifications/read", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.userNotifications = [];
          }
        });
    },
    getAppVersion() {
      axios.get(this.getApiUrl + "/app/version").then((response) => {
        if (localStorage.app_version !== undefined) {
          if (localStorage.app_version != response.data.version) {
            localStorage.app_version = response.data.version;
            // hard reload
          }
        } else {
          localStorage.app_version = response.data.version;
        }
      });
    },
  },
  mounted() {
    this.getMessageCount();
    // this.getAppVersion();
    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.Codes = response.data.data;
    });
    this.school_name = localStorage.school_name
      ? localStorage.school_name
      : "SMS";
    if (this.roles.length > 1 && localStorage.selectedSomeRole == "false") {
      this.shouldChooseRole = true;
    }

    this.$store.commit("setVacationDays");

    this.arrowNavigation();
    if (
      localStorage.selectedRole == "Student" &&
      localStorage.updateData == "false"
    ) {
      this.updateDataDialog = true;
    } else {
      this.updateDataDialog = false;
    }

    if (localStorage.getItem("loginAs") === null) {
      localStorage.loginAs = false;
    }
    this.getNotification();
    let _this = this;
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket
        .channel("NotificationReceived")
        .listen("NotificationReceived", (e) => {
          console.log("notification", e);
          console.log(_this.userNotifications);
          if (parseInt(e.user_id) == parseInt(localStorage.id)) {
            // this.getNotification();
            _this.getNotification();
            _this.$refs.notification.sendNotification(e.notification.body);
          }
          console.log(_this.userNotifications);
        });
    }
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket
        .channel("NotificationInbox")
        .listen("NotificationInbox", (e) => {
          console.log("inbox notification ", e);
          if (parseInt(e.user_id) == parseInt(localStorage.id)) {
            this.getMessageCount();
          }
        });
    }
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket.channel("RefreshAll").listen("RefreshAll", (e) => {
        console.log("inbox RefreshAll", e);
        window.location.reload();
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../styles/_variables.scss";
@import "../styles/_print.scss";

/* start notification item style*/

#notificationMenu {
  .notif-content {
    font-size: 14px !important;
    margin-top: 5px;
  }

  .markAsReaded {
    font-size: 14px !important;
    display: inline-block;
    text-align: right;
    cursor: pointer;
    margin-top: 3px;
  }
}
.v-list {
  padding: 0;
}
.v-list-item {
  padding: 0;
}
.nav-icon {
  margin: 0 5px;
}
.notification-item {
  // -webkit-box-shadow: 0px 2px 1px #f0f0f0;
  // box-shadow: 0px 2px 1px #f0f0f0;
  padding: 12px 16px;
  line-height: 1.5 !important;
  cursor: pointer;
}
.type1 {
  border-left: 6px solid $secondary-color;
}

.type2 {
  border-left: 6px solid $main-color;
}
.type3 {
  border-left: 6px solid red;
}
/* end notification item style*/

@mixin display-hidden {
  display: block;
}

.navbar {
  width: 100%;
  box-shadow: 0px 0px 8px 2px $main-gray;
}

.dropdown.notifications {
  .dropdown-menu {
    left: -165px;
    .dropdown-item {
      .not-details {
        p {
          font-size: 5px;
          display: inline-block;
        }
      }

      .not-time {
        p {
          display: inline-block;
          color: $main-color;
          font-size: 3px;
          position: absolute;
          float: right;
        }
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    top: 42px;
    width: 200px;
    left: -55px;
  }

  .dropdown-item {
    height: 60px;
    width: 200px;
    box-shadow: 0px 2px 1px #f0f0f0;
  }
  border-style: none;
  .item1 {
    border-left: 6px solid $secondary-color;
    box-shadow: 0px 2px 1px #f0f0f0;
  }
  .item2 {
    border-left: 6px solid $main-light;
    box-shadow: 0px 2px 1px #f0f0f0;
  }
  .item3 {
    border-left: 6px solid red;
    box-shadow: 0px 2px 1px #f0f0f0;
  }
  .item4 {
    border-left: 6px solid $main-light;
    box-shadow: none;
  }
}
.dropdown-head {
  background-color: $main-color;
  color: #ffffff;
  box-shadow: 0 0 0 0;
  position: relative;
  text-align: center;
  &::after {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid $main-color;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
  }
}

.form-inline {
  //Search Button
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .search-btn {
    border: 1px solid $main-gray;
    border-radius: 20px;
    width: 50px;
    float: left;
    //  animation: search-animation 5s linear 2s infinite alternate;
    transition: width 0.5s ease-in-out;
    .fa-search {
      float: left;
    }
    &:hover {
      width: 250px;

      //  @keyframes search-animation{
      //    to{}
      //  }
    }
  }
  .search-textbox {
    position: absolute;
    z-index: 1;
    left: 35px;

    // display:inline-block;

    &:focus {
      outline: none;
    }
  }
  .search-text {
    display: none;
  }
  .fa-search {
    color: $main-gray;
  }
}

.account-settings,
.notifications {
  margin: auto !important;
}

.acc-type {
  font-size: 12px;
  color: $main-gray;
}
.acc-icon {
  float: right;
}
.fa-bell {
  color: $main-gray;
  transform: scale(1.4);
  width: 25px;

  top: 10%;

  &:hover {
    color: $main-color;
  }
  &:focus {
    color: $main-color;
    background-color: $main-color;
  }
}
.close-icon {
  .fas {
    display: inline-block !important;
    position: absolute;
    z-index: 1;
    right: 25px;
    width: 5px;
    color: $main-gray;
    transform: scale(0.7);
  }
}
a:focus {
  background-color: unset;
  color: unset;
}
#mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  // background: rgb(2, 0, 36);
  // background: linear-gradient(
  //   90deg,
  //   rgba(2, 0, 36, 1) 0%,
  //   rgba(114, 149, 251, 1) 55%,
  //   rgba(192, 208, 255, 1) 100%
  // );
  opacity: 0.6;
}

i.mdi-radiobox-blank,
i.mdi-radiobox-marked,
.v-input--selection-controls__input {
  display: none !important;
}
.roleLabel {
}
.v-label {
  display: block !important;
}
.bannar2 {
  height: 40px !important;
  transition: all 0.4s ease 0s;

  p {
    padding: 8px !important;
    position: relative;
    transition: all 0.4s ease 0s;
    margin-bottom: 0 !important;
  }
}
.bannar {
  transition: all 0.4s ease 0s;
  position: absolute;
  width: 100%;
  top: 40%;
  // vertical-align: middle !important;
  // text-align: center;
  p {
    text-align: center !important;
    padding: 8px !important;
    position: relative;
    transition: all 0.4s ease 0s;
    margin-bottom: 0 !important;
  }
}
.parent {
  position: relative;
  height: 200px;
  width: 100%;

  overflow: hidden;
}
.loginAs {
  background-color: #ffb400;
  width: 600px;
  height: 100%;
  margin: 0;
  border-radius: 50px 0px 0px 50px;
  // margin-right: -2%;

  .loginAs-row {
    margin-top: 2px;
    align-items: baseline;
  }
}

@media (max-width: 600px) {
  .loginAs {
    .loginAs-title {
      font-size: 12px !important;
      margin-bottom: 0 !important;
    }
  }

  .notification-icon,
  .email-icon {
    font-size: 25px !important;
  }
}

// #overlay {
//   position: fixed; /* Sit on top of the page content */
//   // display: none; /* Hidden by default */
//   width: 100%; /* Full width (cover the whole page) */
//   height: 30px; /* Full height (cover the whole page) */
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
//   z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
//   cursor: pointer; /* Add a pointer on hover */
// }

@media (min-width: 960px) {
  .bannar2 p.active {
    // padding-left: 200px;
    left: 125px !important;
  }
}

@media (max-width: 600px) {
  .username {
    font-size: 12px !important;
  }
}
</style>
