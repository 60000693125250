<template>
  <footer class="not-printable" id="footer" :class="{ active: !getSideBarMin }">
    <div class="copyright text-center">
      {{ $t("Copyrights") }} ©
      <a href="https://shifters.tech/" target="_blank">{{ $t("Shifters") }}</a>
      2021. {{ $t("All rights reserved. Designed by") }}
      <a href="https://shifters.tech/" target="_blank">{{ $t("Shifters") }}</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../styles/_print.scss";

#footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;

  // height: 2.5rem;

  .copywrite {
    // margin-top: 1rem;
  }
}

@media (min-width: 960px) {
  #footer.active {
    // padding-left: 200px;
    left: 125px !important;
  }
}
</style>
